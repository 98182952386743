@value colors: "../../colors.css";
@value light-grey, outline-grey, medium-grey, dark-grey, hover-grey, light-blue, blue-active from colors;

.header {
    background-color: light-grey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.8rem 0 0.8rem 0;
    width: 75%;    
    justify-content: space-evenly;
}

.column-header-button {
    border: 0px;
    border-radius: 0.3rem;
    background-color: medium-grey;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-shadow: 1px 1px 1px dark-grey;
    overflow-wrap: anywhere;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.column-header-button:hover {
    background-color: hover-grey;
}

.column-header-button.active {
    background-color: light-blue;
    outline: 1px solid blue-active;
}

.column-header-button.pressed {    
    outline: 2px solid outline-grey;
}

.search-form {
    margin-left: 1rem;
}

.headers-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-column {
    display: flex;
    justify-content: center;
    width: 25%;
}