@value colors: "../../colors.css";
@value red-warning, red-active, blue-hover, dark-orange-hover, red-warning, green-delivered, yellow-warning from colors;

.information-icon {
    color: blue-hover;
}

.warning-icon {
    color: yellow-warning;
}

.error-icon {
    color: red-warning;
}

.admin-icon {
    color: red-warning;
}

.delivered-icon {
    color: green-delivered;
}

.not-delivered-icon {
    color: red-active;
}