@value colors: "../../../colors.css";
@value light-blue, light-orange, medium-grey from colors;

.row {
    background-color: light-blue;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px 0;
}

.wrapped-area {
    box-shadow: 2px 2px 2px medium-grey;
    opacity: 0.99;
}

.commonName-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 15%;
    margin-right: 2%;
    padding: 0.3rem;
}

.serialNumber-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 15%;
    padding: 0.3rem;
}

.createdDate-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 15%;
    padding: 0.3rem;
}

.expirationDate-column {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 15%;
    padding: 0.3rem;
}

.revoked-column {
    display: flex;
    justify-content: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 10%;
    padding: 0.3rem;
}

.revoke-certificate-button-column {
    display: flex;
    justify-content: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 25%;
    padding: 0.3rem;
}

.download-certificate-button-column {
    display: flex;
    justify-content: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 25%;
    padding: 0.3rem;
}

.delete-certificate-button-column {
    display: flex;
    justify-content: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 25%;
    padding: 0.3rem;
}

.row-wrapper {
}

.select-certificate-checkbox {
    height: 1.2rem;
    width: 1.2rem;
}

.actions-panel {
    display: flex;
    flex-direction: row;
}