@value colors: "../../../colors.css";
@value light-grey from colors;

.header {
    background-color: light-grey;
    display: flex;
    flex-direction: row;
    padding: 0.8rem 0 0.8rem 0;
}

.message-header-column {
    display: flex;
    justify-content: center;
    width: 25%;
}

.messageType-header-column {
    display: flex;
    justify-content: center;
    width: 25%;
}

.createdDate-header-column {
    display: flex;
    justify-content: center;
    width: 25%;
}