.icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    transition: transform 0.2s ease-in-out;
  }
  
  .icon.expanded {
    transform: rotate(180deg);
  }
  
  .icon.collapsed {
    transform: rotate(180deg);
  }