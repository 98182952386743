@value colors: "../../colors.css";
@value light-grey from colors;

.header {
    background-color: light-grey;
    display: flex;
    flex-direction: row;
    padding: 0.8rem 0 0.8rem 0;
}

.clientId-header-column {
    display: flex;
    justify-content: center;
    width: 30%;
}

.clientName-header-column {
    display: flex;
    justify-content: center;
    width: 30%;
}

.profile-header-column {
    display: flex;
    justify-content: center;
    width: 13%;
}

.delete-device-header-column {
    display: flex;
    justify-content: center;
    width: 27%;
}

.ownerUsername-header-column {
    display: flex;
    justify-content: center;
    width: 13%;
}