.device-message-row-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 5px 0 5px;        
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out;
    opacity: 0;
}

.device-message-row-wrapper.show {
    max-height: 1000px;
    opacity: 1;
}

.device-message-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.show-device-messages-panel-checkbox {
    height: 1.2rem;
    width: 1.2rem;
}

.show-device-messages-panel-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 2px #c4c4c4;
    opacity: .99;
    padding: 5px;
}

.admin-message {
    background-color: #ffd3e1;
}