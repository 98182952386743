@value colors: "../../../colors.css";
@value light-blue, light-orange, medium-grey from colors;

.row {
    background-color: light-blue;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 2px 0;
}

.wrapped-area {
    box-shadow: 2px 2px 2px medium-grey;
    opacity: 0.99;
}

.username-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 5rem;
    margin-left: 10px;
    padding: 0.3rem;
    width: 100%;
}

.createdDate-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 5rem;
    margin-left: 10px;
    padding: 0.3rem;
    width: 100%;
}

.description-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 5rem;
    margin-left: 10px;
    padding: 0.3rem;
    width: 100%;
}

.isActive-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 5rem;
    margin-left: 10px;
    padding: 0.3rem;
    width: 100%;
}

.deviceProfiles-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 5rem;
    margin-left: 10px;
    padding: 0.3rem;
    width: 100%;
}

.user-messages-management-button-column {
    display: flex;
    justify-content: flex-start;
    overflow-wrap: break-word;
    min-width: 5rem;
    padding: 0.3rem;
    width: 100%;
}

.row-wrapper {
    width: 100%;
}

.select-item-column {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 100%;
    margin-left: 5px;
    padding: 0.3rem;
}

.select-item-checkbox {
    height: 1.2rem;
    width: 1.2rem;
}