@value colors: "../../colors.css";
@value blue-active, blue-hover, red-active, outline-grey, hover-grey, red-hover, light-orange, light-orange-outline, light-blue-outline, light-blue, medium-grey, dark-grey, light-grey, white-text, black-text, dark-orange, dark-orange-hover from colors;

.column-header-button {
    border: 0px;
    border-radius: 0.3rem;
    background-color: medium-grey;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-shadow: 1px 1px 1px dark-grey;
    overflow-wrap: anywhere;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.column-header-button:hover {
    background-color: hover-grey;
}

.column-header-button.active {
    background-color: light-blue;
    outline: 1px solid blue-active;
}

.column-header-button.pressed {    
    outline: 2px solid outline-grey;
}

.nav-button {
    border: 0px;
    border-color: medium-grey;
    border-radius: 0.3rem;
    margin: 0.2rem 1rem 0.2rem 0.3rem;
    padding: 0.5rem 0.6rem 0.5rem 0.6rem;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.nav-button a {
    text-decoration: none;
}

.nav-button.active {
    background-color: light-blue;
    outline: 1px solid blue-active;
}

.message-icon-nav-button a {
    text-decoration: none;
}

.message-icon-nav-button.active {
    outline: 1px solid blue-active;
    border-radius: 8px;
}

.start {
    order: 0;
}

.end {
    order: 99;
}

.radio-silence-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: red-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.radio-silence-button:hover {
    background-color: red-hover;
}

.radio-silence-button:disabled {
    background-color: medium-grey;
}

.radio-silence-button.pressed {
    border: 2px solid light-orange-outline;
}

.gsm-audio-mode-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.gsm-audio-mode-button:hover {
    background-color: blue-hover;
}

.gsm-audio-mode-button:disabled {
    background-color: medium-grey;
}

.gsm-audio-mode-button.pressed {
    border: 2px solid light-blue-outline;
}

.radio-audio-mode-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.radio-audio-mode-button:hover {
    background-color: blue-hover;
}

.radio-audio-mode-button:disabled {
    background-color: medium-grey;
}

.radio-audio-mode-button.pressed {
    border: 2px solid light-blue-outline;
}

.relay-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.relay-button:hover {
    background-color: blue-hover;
}

.relay-button:disabled {
    background-color: medium-grey;
}

.relay-button.pressed {
    border: 2px solid light-blue-outline;
}

.schedule-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.schedule-button:hover {
    background-color: blue-hover;
}

.schedule-button:disabled {
    background-color: medium-grey;
}

.schedule-button.pressed {
    border: 2px solid light-blue-outline;
}

.add-device-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.add-device-button:hover {
    background-color: blue-hover;
}

.add-device-button:disabled {
    background-color: medium-grey;
}

.add-device-button.pressed {
    border: 2px solid light-blue-outline;
}

.add-device-message-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.add-device-message-button:hover {
    background-color: blue-hover;
}

.add-device-message-button:disabled {
    background-color: medium-grey;
}

.add-device-message-button.pressed {
    border: 2px solid light-blue-outline;
}

.clientId-header-button {
    flex-basis: 7.2rem;
    overflow-wrap: anywhere;
}

.clientName-header-button {
    flex-basis: 8.2rem;
    overflow-wrap: anywhere;
}

.profile-header-button {
    flex-basis: 8.2rem;
    overflow-wrap: break-word;
}

.select-device-header-button {
    flex-basis: 4.6rem;
    min-height: 1.833rem;
    overflow-wrap: anywhere;
}

.delete-device-header-button {
    flex-basis: 4.8rem;
    min-height: 1.833rem;
    overflow-wrap: anywhere;
}

.login-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.login-button:hover {
    background-color: blue-hover;
}

.login-button:disabled {
    background-color: medium-grey;
}

.login-button.pressed {
    border: 2px solid light-blue-outline;
}

.restore-default-settings-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 11rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.restore-default-settings-button:hover {
    background-color: blue-hover;
}

.restore-default-settings-button:disabled {
    background-color: medium-grey;
}

.restore-default-settings-button.pressed {
    border: 2px solid light-blue-outline;
}

.save-current-settings-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 11rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.save-current-settings-button:hover {
    background-color: blue-hover;
}

.save-current-settings-button:disabled {
    background-color: medium-grey;
}

.save-current-settings-button.pressed {
    border: 2px solid light-blue-outline;
}

.radioSilence-for-n-minutes-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.radioSilence-for-n-minutes-button:hover {
    background-color: blue-hover;
}

.radioSilence-until-datetime-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 12rem;
}

.radioSilence-until-datetime-button:hover {
    background-color: blue-hover;
}

.radioSilence-until-timestamp-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.radioSilence-until-timestamp-button:hover {
    background-color: blue-hover;
}

.ownerUsername-header-button {
    flex-basis: 5rem;
    overflow-wrap: break-word;
}

.wait-for-n-seconds-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 7rem;
}

.wait-for-n-seconds-button:hover {
    background-color: blue-hover;
}

.wait-from-n-to-m-seconds-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 10rem;
}

.wait-from-n-to-m-seconds-button:hover {
    background-color: blue-hover;
}

.wait-until-datetime-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 12rem;
}

.wait-until-datetime-button:hover {
    background-color: blue-hover;
}

.wait-until-timestamp-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.wait-until-timestamp-button:hover {
    background-color: blue-hover;
}

.wait-until-incoming-call-from-phone-number-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.wait-until-incoming-call-from-phone-number-button:hover {
    background-color: blue-hover;
}

.wait-until-any-incoming-call-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.wait-until-any-incoming-call-button:hover {
    background-color: blue-hover;
}

.dial-from-n-gsm-to-phone-number-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 12rem;
}

.dial-from-n-gsm-to-phone-number-button:hover {
    background-color: blue-hover;
}

.hang-up-active-call-for-gsm-module-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.hang-up-active-call-for-gsm-module-button:hover {
    background-color: blue-hover;
}

.set-gsm-mode-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 10rem;
}

.set-gsm-mode-button:hover {
    background-color: blue-hover;
}

.set-mp3-file-number-for-gsm-playback-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.set-mp3-file-number-for-gsm-playback-button:hover {
    background-color: blue-hover;
}

.set-radio-mode-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 10rem;
}

.set-radio-mode-button:hover {
    background-color: blue-hover;
}

.set-mp3-file-number-for-radio-playback-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.set-mp3-file-number-for-radio-playback-button:hover {
    background-color: blue-hover;
}

.set-radio-channel-for-first-transceiver-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.set-radio-channel-for-first-transceiver-button:hover {
    background-color: blue-hover;
}

.set-radio-channel-for-second-transceiver-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.set-radio-channel-for-second-transceiver-button:hover {
    background-color: blue-hover;
}

.set-radio-channel-for-both-transceivers-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 8.1rem;
}

.set-radio-channel-for-both-transceivers-button:hover {
    background-color: blue-hover;
}

.once-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.once-button:hover {
    background-color: blue-hover;
}

.repeat-n-times-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 8rem;
}

.repeat-n-times-button:hover {
    background-color: blue-hover;
}

.play-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.play-button:hover {
    background-color: blue-hover;
}

.clear-schedule-field-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 3rem;
    width: 9rem;
}

.clear-schedule-field-button:hover {
    background-color: blue-hover;
}

.delete-latest-command-from-the-end-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 3rem;
    width: 9rem;
}

.delete-latest-command-from-the-end-button:hover {
    background-color: blue-hover;
}

.play-gsm-monologue-file-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.play-gsm-monologue-file-button:hover {
    background-color: blue-hover;
}

.play-radio-dmr-file-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 5rem;
    width: 6rem;
}

.play-radio-dmr-file-button:hover {
    background-color: blue-hover;
}

.schedule-title-header-button {
    flex-basis: 4.8rem;
    min-height: 1.833rem;
    overflow-wrap: anywhere;
}

.schedule-description-header-button {
    flex-basis: 4.8rem;
    min-height: 1.833rem;
    overflow-wrap: anywhere;
}

.scheduleString-header-button {
    flex-basis: 5.8rem;
    min-height: 1.833rem;
    overflow-wrap: anywhere;
}

.select-schedule-header-button {
    flex-basis: 4.8rem;
    min-height: 1.833rem;
    overflow-wrap: anywhere;
}

.delete-schedule-header-button {
    flex-basis: 4.8rem;
    min-height: 1.833rem;
    overflow-wrap: anywhere;
}

.add-schedule-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 3rem;
    width: 12rem;
}

.add-schedule-button:hover {
    background-color: blue-hover;
}

.add-schedule-button.pressed {    
    outline: 2px solid light-blue-outline;
}

.add-schedule-button:disabled {
    background-color: medium-grey;
}

.edit-schedule-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 3rem;
    width: 13rem;
}

.edit-schedule-button:hover {
    background-color: blue-hover;
}

.edit-schedule-button.pressed {    
    outline: 2px solid light-blue-outline;
}

.edit-device-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 3rem;
    width: 13rem;
}

.edit-device-button:hover {
    background-color: blue-hover;
}

.edit-device-button.pressed {    
    outline: 2px solid light-blue-outline;
}

.take-device-away-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 8rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.take-device-away-button:hover {
    background-color: blue-hover;
}

.take-device-away-button:disabled {
    background-color: medium-grey;
}

.take-device-away-button.pressed {
    border: 2px solid light-blue-outline;
}

.take-device-away-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 8rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.revoke-certificate-button:hover {
    background-color: red-hover;
}

.revoke-certificate-button:disabled {
    background-color: medium-grey;
}

.revoke-certificate-button.pressed {
    border: 2px solid light-orange-outline;
}

.revoke-certificate-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: red-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 8rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.undo-revocation-button:hover {
    background-color: dark-orange-hover;
}

.undo-revocation-button:disabled {
    background-color: medium-grey;
}

.undo-revocation-button.pressed {
    border: 2px solid light-orange-outline;
}

.undo-revocation-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: dark-orange;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 8rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.generate-certificate-button:hover {
    background-color: blue-hover;
}

.generate-certificate-button:disabled {
    background-color: medium-grey;
}

.generate-certificate-button.pressed {
    border: 2px solid light-blue-outline;
}

.generate-certificate-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 8rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.download-certificate-button:hover {
    background-color: blue-hover;
}

.download-certificate-button:disabled {
    background-color: medium-grey;
}

.download-certificate-button.pressed {
    border: 2px solid light-blue-outline;
}

.download-certificate-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 8rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.regenerate-crl-button:hover {
    background-color: blue-hover;
}

.regenerate-crl-button:disabled {
    background-color: medium-grey;
}

.regenerate-crl-button.pressed {
    border: 2px solid light-blue-outline;
}

.regenerate-crl-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 8rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.certificates-management-button:hover {
    background-color: blue-hover;
}

.certificates-management-button:disabled {
    background-color: medium-grey;
}

.certificates-management-button.pressed {
    border: 2px solid light-blue-outline;
}

.certificates-management-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    width: 8rem;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.commonName-header-button {
    flex-basis: 8rem;
    overflow-wrap: anywhere;
}

.serialNumber-header-button {
    flex-basis: 8rem;
    overflow-wrap: anywhere;
}

.createdDate-header-button {
    flex-basis: 8rem;
    overflow-wrap: anywhere;
}

.expirationDate-header-button {
    flex-basis: 8rem;
    overflow-wrap: anywhere;
}

.revoked-header-button {
    flex-basis: 8rem;
    overflow-wrap: anywhere;
}

.messageButton-header-button {
    flex-basis: 7rem;
    overflow-wrap: anywhere;
}

.messageType-header-column {
    flex-basis: 7rem;
    overflow-wrap: anywhere;
}

.createdDate-header-column {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.usernameButton-header-button {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.descriptionButton-header-button {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.createdDateButton-header-button {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.messageButton-header-button {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.user-message-management-header-button {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.user-message-management-createdDate-header-button {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.user-message-header-button {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.user-message-createdDate-header-button {
    flex-basis: 10rem;
    overflow-wrap: anywhere;
}

.add-user-message-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.add-user-message-button:hover {
    background-color: blue-hover;
}

.add-user-message-button:disabled {
    background-color: medium-grey;
}

.add-user-message-button.pressed {
    border: 2px solid light-blue-outline;
}

.add-firmware-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    margin-top: 10px;
    -webkit-transition: background-color 0.35s ease;
    -moz-transition: background-color 0.35s ease;
    -ms-transition: background-color 0.35s ease;
    -o-transition: background-color 0.35s ease;
    transition: background-color 0.35s ease;
}

.add-firmware-button:hover {
    background-color: blue-hover;
}

.add-firmware-button:disabled {
    background-color: medium-grey;
}

.add-firmware-button.pressed {
    border: 2px solid light-blue-outline;
}

.edit-firmware-button {
    border: 0;
    border-radius: 0.3rem;
    background-color: blue-active;
    color: white;
    overflow-wrap: break-word;    
    height: 3rem;
    width: 13rem;
}

.edit-firmware-button:hover {
    background-color: blue-hover;
}

.edit-firmware-button.pressed {    
    outline: 2px solid light-blue-outline;
}