@value colors: "../../../colors.css";
@value blue-active from colors;

.return-to-firmware-management-link {
    color: blue-active;
    margin: 11rem 0 0 1rem;
}

.firmware-management {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 2% 2% 2% 2%;
}

.firmware-details-editor-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 100%;
}

.label-container {
    display: flex;
    flex-direction: row;
}

.form-control {
    width: 13rem;
  }