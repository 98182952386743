@value colors: "../../../colors.css";
@value light-blue, light-orange, medium-grey from colors;

.row {
    background-color: light-blue;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px 0;
}

.wrapped-area {
    box-shadow: 2px 2px 2px medium-grey;
    opacity: 0.99;    
    width: 75%;
}

.message-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 5rem;
    margin-left: 10px;
    padding: 0.3rem;
    width: 30%;
}

.createdDate-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 5rem;
    padding: 0.3rem;
}

.delete-user-message-button-column {
    display: flex;
    justify-content: center;
    overflow-wrap: anywhere;
    min-width: 5rem;
    padding: 0.3rem;
}

.row-wrapper {
    width: 110%;
}