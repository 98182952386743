.latest-sent-commands-panel-wrapper {
    margin-bottom: 5px;
}

.latest-sent-commands-row-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 5px 0 5px;        
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out;
    opacity: 0;
}

.latest-sent-commands-row-wrapper.show {
    max-height: 1000px;
    opacity: 1;
}

.latest-sent-commands-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.show-latest-sent-commands-checkbox {
    height: 1.2rem;
    width: 1.2rem;
}

.show-latest-sent-commands-panel-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 2px #c4c4c4;
    opacity: .99;
    padding: 5px;
}