@value colors: "../../../colors.css";
@value light-grey from colors;

.header {
    background-color: light-grey;
    display: flex;
    flex-direction: row;
    padding: 0.8rem 0 0.8rem 0;
}

.commonName-header-column {
    display: flex;
    justify-content: center;
    width: 20%;
}

.serialNumber-header-column {
    display: flex;
    justify-content: center;
    width: 20%;
}

.createdDate-header-column {
    display: flex;
    justify-content: center;
    width: 20%;
}

.expirationDate-header-column {
    display: flex;
    justify-content: center;
    width: 20%;
}

.revoked-header-column {
    display: flex;
    justify-content: center;
    width: 20%;
}