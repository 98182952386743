.add-firmware-wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
  }
  
  .form-control {
    width: 13rem;
  }
  
  .label-container {
    display: flex;
    flex-direction: row;
  }

  .select-all-firmware-checkbox {
    height: 1.2rem;
    width: 1.2rem;
}

.select-all-firmware-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}