@value colors: "../../../colors.css";
@value light-blue, light-orange, medium-grey from colors;

.row {
    background-color: light-blue;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px 0;
}

.wrapped-area {
    box-shadow: 2px 2px 2px medium-grey;
    opacity: 0.99;
}

.message-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 25%;
    margin-left: 10px;
    padding: 0.3rem;
}

.messageType-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 25%;
    padding: 0.3rem;
}

.createdDate-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width:25%;
    padding: 0.3rem;
}

.delete-device-message-button-column {
    display: flex;
    justify-content: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 25%;
    padding: 0.3rem;
}